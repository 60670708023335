
	import Vue from "vue"
	import { Component } from "vue-property-decorator"
	import DayPart from "@components/supplemental/DayPart.vue"
	import Jumbotron from "@components/panel/Jumbotron.vue"
	import { User } from "store/user/types"
	import { userModule } from "store/modules"

	@Component({
		components: {
			DayPart,
			Jumbotron
		}
	})
	export default class WelcomeCard extends Vue {
		@userModule.Getter user!: User
		@userModule.Getter name!: string

		readonly baseTranslationKey = "welcomeCard"

		get userName(): string {
			return this.name || ""
		}
	}
