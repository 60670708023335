
	import Vue from "vue"
	import { Component } from "vue-property-decorator"

	import Card from "@components/panel/Card.vue"
	import CardSection from "@components/supplemental/CardSection.vue"
	import LinkButton from "@components/button/LinkButton.vue"
	import PMobileLink from "@portal-components/dashboard/PMobileLink.vue"
	import PIcon from "@portal-components/ui/PIcon.vue"
	import { userModule } from "store/modules"

	@Component({
		components: {
			Card,
			CardSection,
			PIcon,
			LinkButton,
			PMobileLink
		}
	})
	export default class ProfileWidget extends Vue {
		@userModule.Getter email?: string

		readonly urlToProfilePage = "profile.html"
		readonly baseTranslationKey = "profileWidget"
	}
