
	import { Component, Watch } from "vue-property-decorator"
	import VueModel from "@lib/model/VueModel"
	import Card from "@components/panel/Card.vue"
	import List from "@components/generic/List.vue"
	import PIcon from "@portal-components/ui/PIcon.vue"
	import Message from "@components/widget/Message.vue"
	import { Quotation, QuotationStatus } from "store/quotation/types"
	import pad from "@lib/number/pad"
	import ModelProxy from "@lib/model/ModelProxy"
	import { Cardinality, Identifiable, Mode } from "@lib/types/model"

	import { authenticationModule, quotationModule } from "store/modules"
	import { quotationModel } from "store/index"

	@Component({
		components: {
			Card,
			List,
			PIcon,
			Message
		}
	})
	export default class ActivityWidget extends VueModel {
		@quotationModule.Getter("items") activities!: Array<Quotation>
		@authenticationModule.Getter loggedIn!: boolean

		readonly baseTranslationKey = "activityWidget"

		quotation: ModelProxy<Identifiable, Cardinality, unknown> | null = null

		private static readonly MAX_ACTIVITY_HISTORY = 3
		get visibleQuotations(): Array<Quotation> {
			if (!this.activities) {
				return []
			}
			const ordered = this.orderByLastUpdated(this.activities)
			return ordered.slice(0, ActivityWidget.MAX_ACTIVITY_HISTORY)
		}

		@Watch("loggedIn", { immediate: true })
		loggedInChanged(): void {
			if (!this.loggedIn) {
				return
			}

			this.attach(quotationModel, Mode.READONLY, () => {
				this.quotation?.unfocus()
			})
		}

		canBeClicked(quotation: Quotation): boolean {
			return ![QuotationStatus.FINAL, QuotationStatus.EXPIRED, QuotationStatus.DECLINED].includes(quotation.status!)
		}

		continueQuotation(quotation: Quotation): void {
			switch (quotation.status) {
				case QuotationStatus.CONCEPT:
				case QuotationStatus.QUOTE:
					this.focus(quotation.id!)
					this.goToNewQuotationPage(quotation.id!)
					break
				case QuotationStatus.REQUEST:
				case QuotationStatus.PENDING:
				case QuotationStatus.FINALIZING:
					this.focus(quotation.id!)
					this.goToRequestProductPage()
					break
				case QuotationStatus.FINAL:
				case QuotationStatus.EXPIRED:
				case QuotationStatus.DECLINED:
					break
				default:
					this.focus(quotation.id!)
					this.goToNewQuotationPage(quotation.id!)
					break
			}
		}

		formatDate(date: Date): string {
			const weekPart = `${ pad(2, date.getDate()) }-${ pad(2, date.getMonth() + 1) }-${ date.getFullYear() }`
			const timePart = `${ pad(2, date.getHours()) }:${ pad(2, date.getMinutes()) }`
			return `${ weekPart } ${ timePart }`
		}

		private focus(id: string): void {
			this.quotation?.focus(id)
		}

		private goToNewQuotationPage(quotationId: string): void {
			location.href = "new-quotation.html?id=" + quotationId
		}

		private goToRequestProductPage(): void {
			location.href = "request-product.html"
		}

		private orderByLastUpdated(quotations: Array<Quotation>): Array<Quotation> {
			return quotations.sort((a, b) => b.lastUpdated!.getTime() - a.lastUpdated!.getTime())
		}
	}
