
	import Vue from "vue"
	import Component from "vue-class-component"
	import { StoreAction } from "@lib/types/vuex"
	import Page from "custom/Page.vue"
	import Highlights from "custom/pages/index/Highlights.vue"
	import ActivityWidget from "custom/pages/index/ActivityWidget.vue"
	import MessagesWidget from "custom/pages/index/MessagesWidget.vue"
	import ProfileWidget from "custom/pages/index/ProfileWidget.vue"
	import WelcomeCard from "custom/pages/index/WelcomeCard.vue"
	import Loader from "custom/Loader.vue"
	import { authenticationModule, userModule } from "store/modules"
	import { LoginActionPayload } from "store/authentication/actions"
	import { AuthenticationMethod, sessionFactory } from "utils/session-factory"

	@Component({
		components: {
			Page,
			Highlights,
			MessagesWidget,
			ProfileWidget,
			ActivityWidget,
			WelcomeCard,
			Loader
		}
	})
	export default class Index extends Vue {
		@authenticationModule.Action login!: StoreAction<LoginActionPayload>
		@userModule.Getter isUserLoading!: boolean

		private static readonly SAML_ART_PARAM = "SAMLart"

		async mounted(): Promise<void> {
			if (!process.env.SERVER) {
				await this.handleSamlArtResponse()
			}
		}

		private async handleSamlArtResponse(): Promise<void> {
			const urlParams = new URLSearchParams(window.location.search)
			const samlArt = urlParams.get(Index.SAML_ART_PARAM)

			if (samlArt) {
				await this.login({ authenticationMethod: AuthenticationMethod.ERECOGNITION })
			} else {
				sessionFactory.session.verify()
			}
		}
	}
